import React, { memo, useEffect, useMemo, useState } from 'react';
import TweenOne from 'rc-tween-one';
import Carousel from 'nuka-carousel';
import './../../assets/styles/Carrossel.css';
import pngLoader from '../../assets/images/anim_intro_minicov_text.gif';
import { playSong, stopSong } from '../../utils/song'
import songsName from '../../enums/songsName'

const Carrossel = (props) => {
	const { currentBookID, payload, appState, changeReader } = props;
	const [ SLIDES, setSlide ] = useState([]);
	const [ slideIndexToShow, setSlideIndex ] = useState(0);
	const [ urlGifSurprise, setGifSurprise ] = useState('');

	const isSurprise = appState === 'firsttimevisit' ? true : false;
	useEffect(
		() => {
			props.changeToLandingPage();
			const CURRENT_BOOKS = [];
			const listData = [...payload.listData].reverse();
			const totalBooks = listData.length;
			const currentYear = payload.currentYear;
			let bookIdx = 0;

			// se estiver abrindo a aplicação...
			if (currentBookID === 0) {
				for (let i = 0; i < totalBooks; i++) {
					const element = listData[i];
					if (element.bookYear === currentYear) {
						if (element.bookMonthName === payload.currentMonthName) {
							bookIdx = i;
							i = totalBooks;
						}
					}
				}
				// captura um ID mais recente, aplica e restarta o sistema
				return props.onSlideChange(listData[bookIdx].bookId);
			}

			// descobre o índice a partir do ID do livro
			for (let i = 0; i < totalBooks; i++) {
				const element = listData[i];
				if (currentBookID === element.bookId) {
					bookIdx = i;
					i = totalBooks;
				}
			}

			const ITEM = listData[bookIdx];
			const currentBookMonthName = ITEM.bookMonthName;
			const currentBookYear = ITEM.bookYear;

			// guarda endereço gif surprise
			setGifSurprise(ITEM.bookCoverGIF);

			// monta o array para o carrossel
			listData.forEach((element) => {
				// seleciona apenas os livros do mesmo mês (e ano)
				if (element.bookYear === currentBookYear && element.bookMonthName === currentBookMonthName) {
					CURRENT_BOOKS.push(
						<div
							id={element.bookId}
							key={element.bookId}
							className="carrossel_slide"
							style={{ backgroundImage: `url(${element.bookCover})` }}
							onClick={(e) => {
								const selected = CURRENT_BOOKS.find((item) => item.key === e.target.id);
								const indexInCarrossel = CURRENT_BOOKS.indexOf(selected);
								if (element.bookDownloadFile && indexInCarrossel === slideIndexToShow) {
									playSong(songsName.TAP)
          				playSong(songsName.BUBBLES )
									changeReader(element);
								}
								props.onSlideChange(e.target.id);
							}}
						/>
					);
				}
			});

			// descobre o índice do livro escolhido na lista atualizada
			for (let j = 0; j < CURRENT_BOOKS.length; j++) {
				const element = CURRENT_BOOKS[j];
				if (element.key === currentBookID) {
					setSlideIndex(j);
					j = CURRENT_BOOKS.length;
				}
			}

			setSlide(CURRENT_BOOKS);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentBookID, payload.currentMonthName, payload.currentYear, payload.listData, slideIndexToShow]
	);

	return (
		<div id="carrossel" className="carrossel_container">
			<TweenOne
				className="carrossel_wrap"
				paused={isSurprise}
				animation={{
					opacity: 1
				}}
			>
				<Carousel
					// autoplay={ableToAutoplay}
					withoutControls={true}
					slideIndex={slideIndexToShow}
					beforeSlide={() => {
						playSong(songsName.TAP)
					}}
					afterSlide={(index) => {
						const ID = SLIDES[index].key;
						props.onSlideChange(ID);
					}}
				>
					{SLIDES}
				</Carousel>
			</TweenOne>
			{useMemo(() => (
				<TweenOne
					id="boat"
					className="boat_wrap"
					paused={isSurprise}
					animation={{
						opacity: 0,
						x: window.innerWidth * 2,
						onStart: () => {
							playSong(songsName.ACEAN_WAVES)
						},
						onComplete: () => {
							stopSong(songsName.ACEAN_WAVES)
							const element = document.getElementById('boat');
							element.classList.add('boat_none');
						}
					}}
				>
					<img className="boat_animation" src={pngLoader} alt={'Loading...'} />
				</TweenOne>
			), [isSurprise])}
		</div>
	);
};

export default memo(Carrossel);
