import React from "react";
import "./../../assets/styles/AuthTitle.css";

const AuthTitle = () => {
  return (
    <p className="auth_title">
      BEM - VINDO!
      <br /> TEM UM MAR DE HISTÓRIAS E <br /> AVENTURAS ESPERANDO POR <br />
      VOCÊ!
    </p>
  );
};

export default AuthTitle;
