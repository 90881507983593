import React, { Component } from "react";
import Section from "../_section";
import Logo from "../logo";
import AuthTitle from "../auth-title";
import Footer from "../footer";
import AuthMethodLogin from "../auth-method";
import Processing from "../processing";
import CallUsModal from "../faleconosco/faleconosco-modal";
import AuthSelector from "../_auth-selector/index";
import HelpModal from "../help-modal/index";
import songsName from "../../enums/songsName";
import { playSong } from '../../utils/song'

class AuthPage extends Component {
  static defaultProps = {
    appState: null,
    startDelay: 1000,
    onRefresh: () => console.log("missing refresh callback function"),
  };

  constructor(props) {
    super(props);
    this.processing = React.createRef();
  }

  state = {
    isReady: true,
    loadingRef: null,
    show_callus: false,
    isSuccess: false,
    phoneInput: true,
    show_help: false,
    welcomeShow: true,
  };

  setPhoneInput = (bool) => {
    this.setState({ phoneInput: bool });
  };

  setIsSuccess = (mode) => {
    this.setState({ isSuccess: mode });
  };

  toggle_callus = (mode) => {
    this.setState({ show_callus: mode });
  };
  toggle_help = (mode) => {
    this.setState({ show_help: mode });
  };

  render() {
    const { isReady, welcomeShow } = this.state;
    const appState = this.props.appState;

    const isAppInitialized = appState === "login" ? true : false;

    if (!isAppInitialized || !isReady) {
      return null;
    }

    return (
      <Section parent={this.props}>
        <HelpModal
          toggle_help={this.toggle_help}
          show_help={this.state.show_help}
        />
        <CallUsModal
          show_callus={this.state.show_callus}
          toggle_callus={this.toggle_callus}
          setIsSuccess={this.setIsSuccess}
          isSuccess={this.state.isSuccess}
        />
        <Processing ref={this.processing} />
        <div className="auth-page-content">
          <Logo appState={appState} />
          {welcomeShow ? (
            <>
              <AuthTitle />
              <button
                className="WelcomeButton"
                onMouseEnter={() => playSong(songsName.TAP)}
                onClick={() => {
                  playSong(songsName.TAP)
                  this.setState({ welcomeShow: !welcomeShow })
                }}
                id="contact"
              >
                VAMOS NAVEGAR!
              </button>
              <button
                className="RegulationButton"
                onMouseEnter={() => playSong(songsName.TAP)}
                onClick={() => {
                  playSong(songsName.TAP)
                  window.location.pathname = '/regulamento'
                }}
              >
                Regulamento
              </button>
            </>
          ) : (
            <AuthMethodLogin
              onSubmit={() => this.processing.current.show()}
              onSubmitError={() => {
                this.processing.current.hide();
                this.props.onError();
              }}
              onComplete={this.props.onRefresh}
              phoneInput={this.state.phoneInput}
              toggle_callus={this.toggle_callus}
            />
          )}
          {/* <AuthSelector
					phone={this.state.phoneInput}
					setPhoneInput={this.setPhoneInput}
					toggle_help={this.toggle_help}
				/>
				<AuthFormLogin
					onSubmit={() => this.processing.current.show()}
					onSubmitError={() => {
						this.processing.current.hide();
						this.props.onError();
					}}
					onComplete={this.props.onRefresh}
					phoneInput={this.state.phoneInput}
				/> */}
          {/* <div className="auth_login_container">
          <button onClick={() => this.toggle_callus(true)} id="contact">
            FALE CONOSCO
          </button>
        </div> */}
          <Footer />
        </div>
      </Section>
    );
  }
}

export default AuthPage;
