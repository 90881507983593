import React from "react";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'

const Regulamento = props => {
  return (
    <div className="modal-regulamento">
      <div className="modal-regulamento-container">
        <h2>
          REGULAMENTO DA PLATAFORMA MINI CONTA OUTRA VEZ!
        </h2>
        <br />
        <h2>1. O OBJETO</h2>
        <p>
          O Presente Termo regula as condições de uso do{" "}
          Mini Conta Outra Vez!, plataforma de distribuição de livros
          infantis digitais que permite a seus usuários, doravante Participantes
          Usuários, receber mensalmente um título, por eles adquirido, em
          formato eletrônico, que poderá ser baixado pelos Participantes
          Usuários para leitura em seus dispositivos como smartphone, tablet ou
          computador.
        </p>
        <p>
          O Regulamento ora apresentado, segue, dentre outros, os princípios da
          informação, transparência e boa-fé, observando as leis nº 8.069/1990,
          nº 8.078/1990 e nº 10.406/2002.
        </p>
        <p>
          Os Participantes Usuários declaram que ao acessarem e utilizarem a
          plataforma Mini Conta Outra Vez! leram integralmente e
          aceitaram os termos deste Regulamento na forma como aqui expressos,
          tendo acesso aos títulos distribuídos.
        </p>
        <br />
        <h2>
          2. O ACESSO AO MINI CONTA OUTRA VEZ!
        </h2>
        <p>
          2.1. Poderão ser Participantes Usuários somente as pessoas jurídicas e
          as pessoas físicas capazes maiores de 18 (dezoito) anos.
        </p>
        <p>
          2.2. O Participante Usuário, nos termos da legislação aplicável à
          espécie, declara desde já que o seu cadastro para utilização da
          plataforma, e consequente aceitação deste Regulamento, é responsável
          pelos seus atos na utilização do Mini Conta Outra Vez!, no caso
          de qualquer conduta ilícita, na forma das legislações vigentes, bem
          como pela eventual utilização indevida ou por propagação do material
          disponibilizado sem prévia e expressa autorização.
        </p>
        <p>
          2.3. Após ciência de todos os termos do presente regulamento, o
          Participante Usuário terá acesso às edições digitais, sendo de sua
          inteira faculdade baixar os títulos oferecidos, observando os prazos
          previstos no item 3.5.
        </p>
        <p>
          2.4. Os títulos serão disponibilizados em arquivos formato ePUB, que
          ficam armazenados no dispositivo do Usuário Participante.
        </p>
        <p>
          2.5. Antes baixar os títulos disponibilizados, o Participante Usuário
          deverá reler integralmente este Regulamento. Caso opte pelo download
          de livros, o Usuário concordará automaticamente que houve expressa
          aceitação e concordância de todas as regras, termos e condições
          previstos neste Regulamento. Não será possível a utilização do Mini
          Conta Outra Vez! caso haja qualquer discordância aos presentes termos.
        </p>
        <p>
          2.6. O Participante Usuário se declara proprietário dos exemplares que
          lhe foram disponibilizados na plataforma Mini Conta Outra Vez!.
        </p>
        <p>
          2.7. É de responsabilidade exclusiva dos Participantes Usuários a
          opção pelo download dos títulos oferecidos, assim como fornecer acesso
          ao conteúdo oferecido aos menores de 18 anos de idade que assiste ou
          representa, nos termos da legislação civil brasileira, perante os
          quais o Participante Usuário desempenhará o papel de mediador,
          observando o disposto no art. 73 da lei nº 8.069/1990.
        </p>
        <p>
          2.8. A despeito da criteriosa seleção de títulos e curadoria do
          conteúdo oferecido, o Mini Conta Outra Vez! não se
          responsabiliza, em hipótese alguma, por quaisquer danos que a
          exposição do conteúdo possa causar, particularmente sobre público
          inadequado, bem como o acesso a plataforma por menores de 18
          (dezoito) anos, cabendo ao Participante Usuário, no papel de
          mediador, fazer a avaliação sobre a adequação e conveniência da
          exposição do conteúdo, sobretudo ao público infantil.
        </p>
        <br />
        <h2>3. A PARTICIPAÇÃO E A UTILIZAÇÃO DE CONTEÚDO</h2>
        <p>
          3.1. O Mini Conta Outra Vez! é uma plataforma exclusiva, de
          adesão voluntária pelos Participantes Usuários, por meio de
          contratação direta ou vínculo com as empresas parceiras do{" "}
          Mini Conta Outra Vez!. A elegibilidade ao acesso à plataforma,
          salvo contratação direta, é definida pelas empresas parceiras a seu
          exclusivo critério, cabendo aos elegíveis a decisão de consumar ou não
          sua adesão por meio do download dos livros.
        </p>
        <p>
          3.2. É direito dos Participantes Usuários, a qualquer tempo e sem
          necessidade de justificativa prévia, solicitar a suspensão do envio de
          comunicações pelo Mini Conta Outra Vez! mediante comunicação
          expressa, diretamente ao Mini Conta Outra Vez! por meio da
          seção Fale Conosco ou aos canais de relacionamento da empresa parceira
          a qual esteja vinculado. A suspensão de comunicados não cancela a
          elegibilidade dos Participantes Usuários, que poderão, a qualquer
          momento, enquanto elegíveis, solicitar restabelecimento do envio de
          comunicações do Mini Conta Outra Vez!
        </p>
        <p>
          3.3. Poderá, a qualquer momento e a critério exclusivo e iniciativa da
          empresa parceira do Mini Conta Outra Vez! a qual o Participante
          Usuário está vinculado, haver o cancelamento da participação de
          usuários na medida que estas apontem ao Mini Conta Outra Vez! o
          fim da elegibilidade do Participante Usuário. O cancelamento
          acarretará o não recebimento de novos títulos pelo Participante
          Usuário.
        </p>
        <p>
          3.4. O cancelamento da elegibilidade do Participante Usuário não afeta
          o acesso e a leitura dos conteúdos já adquiridos, observado o prazo do
          item 3.5, sendo de propriedade do Participante Usuário os títulos por
          ele baixados a qualquer tempo. O cancelamento da elegibilidade e por
          consequência, da participação do Participante Usuário não impede o
          download futuro, até o período de um ano desde a data de
          disponibilização dos títulos no período de elegibilidade, mesmo ao
          usuário que tenha se tornado inelegível.
        </p>
        <p>

          3.5. Todo o novo conteúdo adquirido mensalmente pelo Usuário
          Participante estará disponível para download pelo período de um (1)
          ano a contar da data de disponibilização na plataforma, nos termos
          do que dispõe o art. 3º, inciso II do Decreto nº 7.962 de 15 de
          março de 2013. Após este período, não será possível para o Usuário
          participante baixar os títulos disponibilizados em datas anteriores.
          O conteúdo de meses anteriores à adesão, oferecido como bônus ao
          Usuário Participante, estará disponível por períodos menores, que
          variam de acordo com a data de início da disponibilização do
          conteúdo na plataforma, independentemente da data de adesão do
          Usuário Participante.

        </p>
        <p>

          3.6. Considerando os princípios da informação e transparência, A
          perda de acesso a conteúdo disponibilizado há mais de um ano pelo
          Participante Usuário não dará direito a nenhuma compensação,
          indenização ou reparação.

        </p>
        <p>
          3.7. Os Participantes Usuários deverão estar cientes de que o acesso
          ao Mini Conta Outra Vez! e o download de títulos depende de
          tráfego de dados pela rede de dados, estando, pois, sujeito à consumo
          de dados, tarifação e encargos pela operadora de telecomunicações.
        </p>
        <p>

          3.8. O Mini Conta Outra Vez! reserva-se o direito de, a
          qualquer momento e a seu exclusivo critério, descontinuar,
          suspender, terminar a oferta da plataforma, ou ainda alterar a
          quantidade, o formato e a periodicidade dos títulos
          disponibilizados, mantendo a disponibilidade prevista no item 3.5.

        </p>
        <p>
          3.9. Para hipótese de encerramento do Mini Conta Outra Vez!,
          ter-se-á prévia comunicação de 30 (trinta) dias aos Participantes
          Usuários.
        </p>
        <p>
          3.10. A plataforma Mini Conta Outra Vez! é de uso pessoal e não
          comercial, da qual os Participantes Usuários poderão ser excluídos
          e/ou ter a plataforma suspensa, definitiva ou temporariamente, pelos
          seguintes motivos, dentre outros:
        </p>
        <p>
          a. compartilhar, ceder, comercializar, divulgar ou imprimir os
          arquivos baixados;
        </p>
        <p>
          b. modificar, adaptar, licenciar, transferir, editar, publicar,
          traduzir, promover publicação em qualquer outro meio de comunicação,
          criar outras obras derivadas das disponibilizadas, ou de qualquer
          forma explorar ou tirar proveito do conteúdo digital da plataforma
          Mini Conta Outra Vez!;
        </p>
        <p>
          c. comercializar, locar ou transferir o acesso à plataforma Mini Conta
          Outra Vez!;
        </p>
        <p>
          d. adaptar, modificar, comercializar, distribuir, realizar engenharia
          no código fonte da plataforma ou praticar qualquer ato que comprometa,
          total o parcialmente, seu funcionamento, disponibilidade e/ou
          desempenho;
        </p>
        <p>
          e. identificação de qualquer indício ou prova de vício, fraude ou
          irregularidade na participação Participante Usuário;
        </p>
        <p>
          f. por solicitação dos próprios Participantes Usuários ou de
          responsável devidamente habilitado;
        </p>
        <p>g. por solicitação judicial ou de autoridade competente.</p>
        <p>
          h. por determinação e/ou a critério exclusivo da operadora associada
        </p>
        <p>
          i. se apurado o mau uso do Mini Conta Outra Vez!, além de
          infringir a legislação aplicável, especialmente de natureza cível e
          criminal, bem como afronta aos princípios éticos, morais e de boa-fé,
          ou caso haja violação de alguma das disposições contidas neste Termo.
        </p>
        <br />
        <h2>4. PROPRIEDADE INTELECTUAL</h2>
        <p>
          4.1. Todos os conteúdos disponibilizados pelo{" "}
          Mini Conta Outra Vez!, incluindo a própria plataforma, estão
          protegidos pelas Leis de Direitos Autorais e Propriedade Intelectual.
        </p>
        <p>

          4.2. O Participante Usuário é o proprietário dos conteúdos
          adquiridos e por ele baixados, sendo que, por estarem estes
          protegidos pelas leis de direitos autorias, é expressamente proibido
          ao Participante Usuário reproduzir, ainda que parcialmente,
          transmitir, veicular, ceder, negociar ou distribuir qualquer título.

        </p>
        <p>

          4.3. Ao optar fazer download de qualquer título, o Participante
          Usuário concorda em zelar pelos conteúdos baixados e respeitar os
          direitos autorais e a propriedade intelectual de autores e da
          plataforma, assumindo civil e criminalmente a responsabilidade por
          eventuais violações e danos de natureza moral e material causados a
          outrem, independente de natureza e extensão, inclusive nas hipóteses
          de regresso.

        </p>
        <br />
        <h2>5. DAS DISPOSIÇÕES GERAIS</h2>
        <p>
          5.1. Os Participantes Usuários declaram que tem conhecimento de que o
          Mini Conta Outra Vez! não é responsável pelos conteúdos
          distribuídos, que são de responsabilidade única das editoras e dos
          autores dos títulos.
        </p>
        <p>
          5.2. O Mini Conta Outra Vez! declara que não armazena os dados
          pessoais dos Participantes Usuários tampouco compartilha com terceiros
          sua atividade relacionada a visitas e downloads.
        </p>
        <p>
          5.3. Os Participantes Usuários reconhecem o direito do Mini Conta
          Outra Vez!, a qualquer tempo e por ato unilateral, de aditar,
          modificar ou atualizar as regras, termos e condições deste
          Regulamento. Qualquer aditamento, modificação ou atualização serão
          previamente comunicados aos Participantes Usuários, por meio do site
          ou por correio eletrônico. Caso os Participantes Usuários continuem a
          usar o Mini Conta Outra Vez! depois do aviso de aditamento,
          modificação ou atualização deste Regulamento, isso caracterizará a
          aceitação das alterações implementadas. Excluem-se as modificações que
          gerem ônus ou novas cobranças ao usuário.
        </p>
        <p>
          5.4. O Mini Conta Outra Vez! poderá, a qualquer tempo e por ato
          unilateral, ser suspenso, extinto ou modificado, ou ter sua gestão
          transferida, mediante prévia comunicação aos Participantes Usuários,
          por meio do site http://www.contaoutravez.com.br, por correio
          eletrônico aos Participantes Usuários. Em qualquer hipótese, será
          resguardada eventual operação comercial já concluída pelos
          Participantes Usuários até a data da suspensão, extinção ou
          modificação.
        </p>
        <p>
          5.5. Os Participantes reconhecem a obrigatoriedade de observar as leis
          de proteção ao direito autoral e propriedade intelectual e concordam
          em fazê-lo, estando sujeitos, em contrário, às penalidades cíveis e
          criminais aplicáveis.
        </p>
        <p>
          5.6. Os casos omissos neste Regulamento serão tratados diretamente
          entre os Participantes Usuários e o Mini Conta Outra Vez!,
          observando a legislação pertinente.
        </p>
        <p>
          5.7. Qualquer tolerância ao descumprimento ou omissão na execução de
          seus direitos por parte do Mini Conta Outra Vez!, não
          constituirá renúncia, ineficácia ou novação dos direitos e obrigações
          ora pactuados, nem impedirá que o Mini Conta Outra Vez! ou
          qualquer dos autores e/ou editores veiculados na plataforma, ainda que
          extemporaneamente, requeira o cumprimento de tais direitos e
          obrigações, sendo certo que qualquer exceção só se tornará válida-
          desde que expressamente concedida pelo Mini Conta Outra Vez! ou
          qualquer dos parceiros disponibilizados por meio da plataforma.
        </p>
        <p>
          5.8. O Mini Conta Outra Vez! não será considerado em mora ou
          inadimplente em relação a qualquer direito ou obrigação previstos
          neste Regulamento se o motivo do descumprimento decorrer de caso
          fortuito ou força maior, na forma estabelecida pelo Código Civil
          Brasileiro, Lei. nº 10.406, de 10 de janeiro de 2002 e pelo Código de
          Proteção e Defesa do Consumidor, Lei nº 8.078 de 11 de setembro de
          1990.
        </p>
        <p>
          5.9. Na hipótese de qualquer das regras, termos ou condições aqui
          dispostos ser considerado, por qualquer motivo, por juízo ou árbitro
          competente, inválido, inexequível ou ilegal, no todo ou em parte, a
          validade e exequibilidade das demais regras, termos e condições, ou de
          partes deles, não serão afetadas, pois, tratam-se de regras
          independentes entre si, nos termos do que dispõe o art. 51, § 2º da
          lei 8.078/90.
        </p>
        <p>
          5.10. Independentemente do local no território, nacional ou
          internacional, que os Participantes Usuários tenham acesso ao ambiente
          de internet do Mini Conta Outra Vez!, este Regulamento será
          regido, interpretado e executado de acordo com as leis da República
          Federativa do Brasil, independentemente dos conflitos dessas leis com
          leis de outros estados ou países, sendo competente a Justiça
          Brasileira para dirimir qualquer dúvida decorrente deste instrumento.
          O aceite ao presente Regulamento estabelece a renúncia dos
          Participantes Usuários à competência de qualquer outra autoridade
          judiciária, FICANDO DESDE JÁ ELEITO O FORO DO DOMICÍLIO DO
          PARTICIPANTE como competente para dirimir qualquer questão oriunda do
          presente Regulamento, por mais privilegiado que seja ou venha a ser.
        </p>
      </div>
      <div className="modal-button-regulamento-container">
        <button
          onMouseEnter={() => playSong(songsName.TAP)}
          onClick={() => {
            playSong(songsName.TAP)
            props.onRequestClose()
          }}
        >
          FECHAR
        </button>
      </div>
    </div>
  );
};

export default Regulamento;
