import '../../assets/styles/PushNotificationModal.css';

import React from 'react';
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import { usePushContext } from '../../context/pushContext';

function PushNotificationModal({ showEnable }) {
	const {
		showPushModal,
		acceptPushNotification,
		rejectPushNotification,
	} = usePushContext();

	if(!showPushModal || !showEnable) return null;

	return (
		<div className="fog_background">
			<div className="modal_container">
				<div className="modal_footer">
					<button type="button" className="btn accept" onMouseEnter={() => playSong(songsName.TAP)} onClick={acceptPushNotification}></button>
					<button type="button" className="btn dismiss" onMouseEnter={() => playSong(songsName.TAP)} onClick={rejectPushNotification}></button>
				</div>
			</div>
		</div>
	);
}

export default PushNotificationModal;