import React, { Component, createRef } from 'react';
import { default_server } from './services/api';

import AuthPage from './components/_authpage';
import Error404 from './components/_error404';
import LandingPage from './components/_landingpage';
import SimpleLoader from './components/_loader';
import Reader from './components/_reader';
import InternalSongs from './components/internal-songs';
import DataManager from './services/dataManager';
import smoothscroll from 'smoothscroll-polyfill';
import './assets/styles/App.css';
import { isIOSDevice } from './utils/device';
import { Regulamento } from './components/Regulamento';
import InfoButtons from './components/info-buttons';
import { pauseSong, playSong } from './utils/song';
import songsName from './enums/songsName';
import Footer from './components/footer';
import toast, { Toaster } from 'react-hot-toast';

let DATA_MANAGER = DataManager;
const START_DELAY = 2000;
const currentPathname = window.location.pathname;
const IS_REGULATION = currentPathname === '/regulamento';
const HAS_SUBDOMAIN = currentPathname !== '/' && currentPathname.length > 1;
const axios = require('axios');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appState: 'inicializing',
			reader: false,
			showGames: false,
			gameManagerUri: null,
			iframeHeight: '45rem'
		};

		this.iframeRef = createRef()

		// kick off the polyfill!
		smoothscroll.polyfill();
	}

	async componentDidMount() {
		if (HAS_SUBDOMAIN) {
			const token = currentPathname.replace(/[/"]/g, '');
			await window.localStorage.setItem('@minicov/hash', token);
		}

		this.checkToOpenGames()

		window.addEventListener('message', (event) => {
			if (event.data.type === 'ADJUST_SIZE') {
				const offset = 80
				const newHeight = event.data.payload + offset
        this.setState({ iframeHeight: `${newHeight}px` })
      }

			if (event.data.type === 'ADJUST_SCROLL') {
				this.alignScroll()
			}

			if (event.data.type === 'CHANGE_URL') {
				let url = '#jogos'

				if (event.data.payload) {
					url = `#jogos-${event.data.payload}`
				}

				if (event.data.payload) {
					pauseSong(songsName.MAIN_BACKGROUND)
				} else {
					playSong(songsName.MAIN_BACKGROUND)
				}
				
				this.changeURL(url)
			}

			if (event.data.type === 'FAILED_TO_LOAD_GAMES') {
				setTimeout(() => {
					toast('Desculpe, tivemos um problema para carregar os jogos!', {
						duration: 5000, 
					})
	
					this.closeGameHandler()
				}, 1000)
			}

			if ('scrollRestoration' in window.history) {
				window.history.scrollRestoration = 'manual';
			}
		})

		this.inicialize();

		const originalPushState = window.history.pushState;
    window.history.pushState = (...args) => {
			originalPushState.apply(window.history, args);
			window.dispatchEvent(new Event('pushstate'));
		};

		window.addEventListener('popstate', this.onUrlChange);
		window.addEventListener('pushstate', this.onUrlChange);
	}

	checkToOpenGames() {
		const name = window.location.hash.replace('#', '')

		if (name.includes('#jogos')) {
			if (!this.state.showGames) {
				this.openGameHandler()
			}

			const game = name.split('-')[1]

			if (game) {
				this.iframeRef.current.addEventListener('load', () => {
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: game
					}, '*')
				})
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('popstate');
    window.removeEventListener('pushstate');

		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'auto';
		}
	}

	onUrlChange = () => {
		const name = window.location.hash.replace('#', '')

		if (!name) {
			if (this.state.showGames) {
				this.closeGameManager()
			}
		} else if (name.includes('jogos')) {
			if (!this.state.showGames) {
				this.openGameManager()
			}

			setTimeout(() => {
				if (name === 'jogos') {
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: undefined
					}, '*')
				} else if (name.includes('-')) {
					const game = name.split('-')[1]
					this.iframeRef.current.contentWindow.postMessage({
						type: 'CHANGE_PAGE',
						payload: game
					}, '*')
				}
			}, 100)
		}
	}

	changeURL = (data) => {
		if (window.location.hash !== data) {
			window.history.pushState(null, null, data)
		}
	}

	handleOpenReader = (readerState) => this.setState({ appState: 'reader' });

	inicialize() {
		const hash = window.localStorage.getItem('@minicov/hash');

		if (hash) {
			this.setState({
				appState: 'loading'
			});

			axios({
				method: 'get',
				timeout: 20000,
				// url: 'http://www.mocky.io/v2/5e3b80423000006e002145ba',
				url: `${default_server}/getportfolio/${hash}?produto=M`,
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then((response) => {
					if (!'status' in response) {
						throw 'status {str} not found';
					}
					if (response.status === 200 || response.status === 201) {
						if ('listData' in response.data && response.data.listData.length === 0) {
							throw `listData {arr} not found`;
						}
						DATA_MANAGER = new DataManager(response.data);

						if (response.data.game) {
							this.setState({ gameManagerUri: response.data.gameURL })
						}

						return this.loadFromAutoStart();
						// if (DATA_MANAGER.openCollection === 0) {
						// } else {
						// return this.loadFromSurpriseToLandingpageDirect();
						// return this.loadFromBoatToLandingpageDirect()
						// return this.loadFromAutoStart();
						// }
					} else {
						throw `status: ${response.status}`;
					}
				})
				.catch((error) => {
					window.localStorage.removeItem('@minicov/hash');
					return this.loadFromError();
				});
		} else {
			return this.loadFromLogin();
		}
	}

	loadFromLogin() {
		setTimeout(() => {
			this.setState({ appState: 'login' });
		}, START_DELAY);
	}

	loadFromAutoStart = () => {
		setTimeout(() => this.setState({ appState: 'firsttimevisit' }), START_DELAY);
	};

	loadFromSurpriseToLandingpage = () => {
		axios
			.post(
				`${default_server}/opencollection`,
				{
					token: DATA_MANAGER.token,
					mes: DATA_MANAGER.currentMonthName,
					ano: DATA_MANAGER.currentYear,
					produto: 'M'
				},
				{
					timeout: 20000,
					headers: {
						'Content-Type': 'application/json'
					}
				}
			)
			.then((success) => { })
			.catch((error) => { })
			.finally(() => {
				this.setState({ appState: 'landingpage' }, () => {
					this.changeURL('#')
				});
			});
	};

	loadFromSurpriseToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }, () => {
			this.changeURL('#')
		}), 100);
	};

	loadFromBoatToLandingpageDirect = () => {
		setTimeout(() => this.setState({ appState: 'landingpage' }, () => {
			this.changeURL('#')
		}), 1500);
	};

	loadFromError = () => {
		this.setState({ appState: 'error404' });
	};

	loadFromCredentialsPayload = (payload) => {
		DATA_MANAGER = new DataManager(payload);

		if (payload.game) {
			this.setState({ gameManagerUri: payload.gameURL })
		}

		window.localStorage.setItem('@minicov/hash', DATA_MANAGER.token);

		// rola scroll ate o topo
		window.scrollTo(0, 0);
		return this.loadFromAutoStart();
		// return this.loadFromAutoStart;
		// if (DATA_MANAGER.openCollection === 0) {
		//   return this.loadFromAutoStart();
		// }
		// else {
		//   // return this.loadFromSurpriseToLandingpageDirect();
		//   return this.loadFromBoatToLandingpageDirect()
		// }
	};

	toggleFullScreen() {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
		var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

		if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
			requestFullScreen.call(docEl);
		}
		else {
			cancelFullScreen.call(doc);
		}
	}

	changeReader = (bookId) => {
		if (!this.state.reader) {
			this.setState({ reader: bookId }, () => {
				if (!isIOSDevice) {
					this.toggleFullScreen()
				}
			});
		} else {
			this.setState({ reader: false }, () => {
				if (!isIOSDevice) {
					this.toggleFullScreen()
				}
			});
		}
	};

	alignScroll = () => {
		const targetView = document.getElementById('game-manager');
		const targetPosition = targetView.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo({
			left: 0,
			top: targetPosition,
		});
	}

	openGameManager = () => {
		this.setState({ showGames: true })

		setTimeout(() => {
			playSong(songsName.OPEN_GAME)
			const gameManagerElement = window.document.getElementById('game-manager-wrapper')
			gameManagerElement.style.display = 'block'
			setTimeout(() => {
				gameManagerElement.style.opacity = 1
			}, 200)
			
			const ladingPageElement = window.document.getElementById('ladingpage')
			setTimeout(() => {
				ladingPageElement.style.display = 'none'
				ladingPageElement.style.opacity = 0
			}, 600)
		}, 200)
	}

	closeGameManager = () => {
		const ladingPageElement = window.document.getElementById('ladingpage')
		ladingPageElement.style.display = 'block'
		ladingPageElement.style.opacity = 1

		const gameManagerElement = window.document.getElementById('game-manager-wrapper')
		gameManagerElement.style.opacity = 0
		
		setTimeout(() => {
			gameManagerElement.style.display = 'none'
		}, 700)

		setTimeout(() => {
			playSong(songsName.CLOSE_GAME)
			this.setState({ showGames: false })
			window.scrollTo(0, 0)
		}, 200)
	}

	openGameHandler = () => {
		this.changeURL('#jogos')
	}

	closeGameHandler = () => {
		this.changeURL('#')
	}

	render() {
		return (
			<div className="App" id="App">
				{
					IS_REGULATION
						?
						<Regulamento />
						:
						<>
							<SimpleLoader appState={this.state.appState} />

							<Error404 appState={this.state.appState} />

							<AuthPage
								appState={this.state.appState}
								startDelay={START_DELAY}
								payload={{}}
								onRefresh={this.loadFromCredentialsPayload}
								onError={this.loadFromError}
							/>

							{this.state.gameManagerUri && this.state.showGames ? (
								<div id="game-manager-wrapper" className='section_background_landingpage'>
									<div className='game-manager-wrapper'>
										<iframe
											ref={this.iframeRef}
											id="game-manager"
											className='game-manager'
											title='Gerenciador de games'
											src={`${this.state.gameManagerUri}?product=M&token=${DATA_MANAGER.token}`}
											style={{ height: this.state.iframeHeight }}
										/>
										<div className='game_manager_info_button'>
											<InfoButtons closeGameManager={this.closeGameHandler} appState={this.state.appState} />
										</div>
										
										<div className='game_manager_footer'>
											<Footer />
										</div>
									</div>
								</div>
							) : null}

							<div id="ladingpage">
								<LandingPage
									openGames={this.openGameHandler}
									changeReader={this.changeReader}
									appState={this.state.appState}
									isReading={!!this.state.reader}
									changeState={this.loadFromSurpriseToLandingpage}
									changeToLandingPage={this.loadFromBoatToLandingpageDirect}
									startDelay={START_DELAY}
									payload={DATA_MANAGER}
									handleOpenReader={this.handleOpenReader}
									showGameBanner={!!this.state.gameManagerUri}
								/>
							</div>

							<Reader toggleFullScreen={this.toggleFullScreen} reader={this.state.reader} changeReader={this.changeReader} />

							<InternalSongs
								appState={this.state.appState}
								isReading={!!this.state.reader}
								isShowingGames={this.state.gameManagerUri &&  this.state.showGames}
							/>
							<Toaster />
						</>
				}
			</div>
		);
	}
}

export default App;
