import React, { useState, useEffect, memo } from "react";
import Section from "../_section";
import MainTitle from "../main-title";
import Carrossel from "../carrossel";
import BookDetail from "../book-detail";
import Review from "../review";
import OthersTitle from "../others-title";
import OthersBooks from "../others-books";
import InfoButtons from "../info-buttons";
import InfoSmallButtons from "../info-small-buttons";
import Footer from "../footer";
import PwaScreen from '../pwa-screen';
import PushNotificationModal from '../pushNotificationModal';
import { playSong, pauseSong } from "../../utils/song";
import songsName from "../../enums/songsName";
// import AdsModal from "../ads-modal/index";
import imgPassatempos from '../../assets/images/passatempos_1.png'

const LandingPage = (props) => {

	const [isOpenSurprise, setIsOpenSurprise] = useState(null);
  const [openModalBySuprise, setOpenModalBySuprise] = useState(false);
  const [showAdsModal, setShowAdsModal] = useState(true);

	// useEffect(() => {
  //   setTimeout(() => {
  //     return isOpenSurprise
  //       ? setOpenModalBySuprise(true)
  //       : null
  //     ;
  //   }, 5000);
  // }, [isOpenSurprise])

	const isAppInitialized = props.appState === 'firsttimevisit' || props.appState === 'landingpage' ? true : false;

	useEffect(() => {
		if (props.appState === 'firsttimevisit' && !props.isReading) {
			setTimeout(() => {
				playSong(songsName.MAIN_BACKGROUND)
			}, 999);
		}

		if (props.appState === 'landingpage' && !props.isReading) {
			playSong(songsName.MAIN_BACKGROUND)
		}
		
		if (props.isReading) {
			pauseSong(songsName.MAIN_BACKGROUND)
		}
	}, [props.appState, props.isReading]);
	
	if (!isAppInitialized) {
		return <div />;
	}

	const changeReader = props.changeReader;

	const onChangeReader = (props) => {
		playSong(songsName.TAP)
		changeReader(props)
	}

	return (
		<>
			<PushNotificationModal showEnable={props.appState === 'landingpage'} />
			<PwaScreen />

			<Section parent={props}>

				<div id="top-empty-space" className='top-empty-space' />
				<MainTitle
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
				/>
				<Carrossel
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
					changeToLandingPage={props.changeToLandingPage}
					changeReader={onChangeReader}
				/>
				<BookDetail
					handleOpenReader={props.handleOpenReader}
					changeReader={onChangeReader}
				/>
				<Review />

				<div className='content-top-bg' />
				<div className='content-wrapper'>
					{props.showGameBanner ? (
						<div
							className='passatempos-banner-wrapper'
							onClick={() => {
								playSong(songsName.TAP)
								window.scrollTo(0, 0);
								props.openGames()
							}}
						>
							<img className='passatempos-banner' src={imgPassatempos} alt='passatempos' />
						</div>
					): <div />}

					<OthersTitle />
					<OthersBooks />

					<div className='footer-wrapper'>
						<InfoButtons />
						<InfoSmallButtons />
						<Footer ignoreLogo />
					</div>
				</div>

				{/* <AdsModal
					open={showAdsModal}
					onRequestClose={setShowAdsModal}
					changeToLandingPage={props.changeToLandingPage}
				/> */}

			</Section>
		</>
	);
};

export default memo(LandingPage);
