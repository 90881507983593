import firebase from 'firebase';

export const askUserPermission = () => {
	return new Promise((resolve, reject) => {
		Notification.requestPermission().then(result => {
			console.log(`Result: ${result}`)
			if (result === 'denied') {
				reject('Permission wasn\'t granted. Allow a retry.');
				// return;
			}
			if (result === 'default') {
				reject('The permission request was dismissed.');
				// return;
			}
	
			const messaging = firebase.messaging();
			messaging
				.getToken(messaging, {
					//vapidKey: 'BIlCUVRfYs7QKREx6cU6ieKYZczxZ5VpVOfLLs-SQjhv6SIEEsshyqzvfcRzJNMgu4PHl-W0XcnOx8RyebCtZ0g'
					//vapidKey: 'BCGPm7R_SCrW1ZkJ7XmDu5tGrck1Fv_KoQ8ZPq5PDehK9GqWMvJEluLftz-kjJRo46I7D85TWtHCqE2hmmimyu4'
					vapidKey: 'BGhacSlEf0bIP3J6rsymxNMbwahKzOw2jFsFoB-mL1YdQ-Mja82IviER-zP8WPIK8jX0pnlGyu7Ju5rua2RZezQ'
				})
				.then((currentToken) => {
					if (currentToken) {
						resolve(currentToken);
						// console.log('token do usu�rio:', currentToken);
					} else {
						reject('No registration token available. Request permission to generate one.');
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	});
};
	
	// messaging.onMessage(function(payload) {
	// 	// const data = JSON.parse(payload.data.notification);
	// 	new Notification('teste');
	// });