import { songEventsName } from "../enums/events"
import songEvents from "../events/song"

export const playSong = (key) => {
  songEvents.emit(songEventsName.PLAY_SONG, key)
}

export const pauseSong = (key) => {
  songEvents.emit(songEventsName.PAUSE_SONG, key)
}

export const stopSong = (key) => {
  songEvents.emit(songEventsName.STOP_SONG, key)
}