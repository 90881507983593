import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import Regulamento from "./regulamento";
import SeuLivroAqui from "./seuLivroAqui";
import { playSong } from '../../utils/song'
import songsName from '../../enums/songsName'
import "./../../assets/styles/InfoSmallButtons.css";

const InfoSmallButtons = () => {
  const [show_terms, toggle_terms] = useState(false);
  const [show_participe, toggle_participe] = useState(false);
  return (
    <div id="small_buttons" className="info_small_buttons_container">
      <p
        onMouseEnter={() => playSong(songsName.TAP)}
        onClick={() => {
          playSong(songsName.TAP)
          toggle_terms(true)
        }}
      >
        Regulamento
      </p>
      {/* <p>|</p>
      <p onClick={() => toggle_participe(true)}>Você é autor? </p>
      <p onClick={() => toggle_participe(true)}> Seu livro aqui.</p> */}

      <Drawer dontApplyListeners={true} open={show_terms} onRequestClose={() => toggle_terms(false)}>
        <Regulamento onRequestClose={() => toggle_terms(false)} />
      </Drawer>

      <Drawer
        dontApplyListeners={true}
        // open={show_participe}
        open={false}
        onRequestClose={() => toggle_participe(false)}
      >
        <SeuLivroAqui onRequestClose={() => toggle_participe(false)} />
      </Drawer>
    </div>
  );
};

export default InfoSmallButtons;
